$(document).ready(function () {

  let modalToggle = $('.modal-toggle');

  if (modalToggle.length) {
    modalToggle.magnificPopup({
      removalDelay: 300, callbacks: {
        beforeOpen: function () {
          this.st.mainClass = this.st.el.attr('data-effect');
        }
      }
    });
  }

  let inputFile = $('input[type=file]');

  if (inputFile.length) {
    inputFile.bootstrapFileInput();
  }

  $('.mobile-toggle').on('click', function () {
    $('.mobile-nav-overlay').fadeIn();
    $('.mobile-nav').addClass('in');
  });

  $('.mobile-nav-overlay').on('click', function () {
    $('.mobile-nav-overlay').fadeOut();
    $('.mobile-nav').removeClass('in');
  });

  $('.menu a[href^="#"], a.btn[href^="#"]').on('click', function () {
    let target = $(this).attr('href');
    let top = $(target).offset().top - 140;
    $('html, body').animate({scrollTop: top}, 600);
    return false;
  });

  // imgLiquid init
  $(".img-fill").imgLiquid({
    fill: true,
    horizontalAlign: "center",
    verticalAlign: "center",
    maxWidth: 576
  });

  $(".img-no-fill").imgLiquid({
    fill: false,
    horizontalAlign: "center",
    verticalAlign: "center"
  });

  $('.phoneInput').mask('+7 (000) 000-00-00');

  $('.fancybox').fancybox();

  let serviceDetailSlider = new Swiper('#serviceDetailSlider', {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 30,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  // detect device

  let isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|BlackBerry|Android)/i) != null;
  let mouseDown = "mousedown";
  let mouseMove = "mousemove";
  let mouseUp = "mouseup";

  if (isMobile) {
    console.log('is mobile');
    mouseDown = "touchstart";
    mouseMove = "touchmove";
    mouseUp = "touchend";
  }

  let body = document.querySelector('body');

  body.addEventListener(mouseUp, function (e) {

    let menu = e.target.dataset.menu;

    if (!e.target.closest('.header-menu-wrapper') && !e.target.closest('.menu-btn')) {
      body.classList.remove('mobile-menu-opened');
    }

    if (menu) {
      if (menu === "toggle") {
        body.classList.toggle('mobile-menu-opened');
      }
      if (menu === "close") {
        body.classList.remove('mobile-menu-opened');
      }
    }

  });

  /*
  $.magnificPopup.open({
    items: {
      src: '<div id="modal-success" class="white-popup"><div class="text"><div class="title">Ваша заявка успешно отправлена!</div><p>Наш специалист свяжется с Вами в ближайшее время.</p></div></div>',
      type:'inline'
    }
  });
  */

});